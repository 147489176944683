import { Navigate, RouterProvider, createHashRouter } from "react-router-dom";
import Loader from "../components/common/loader";
import { Suspense } from "react";
import ToastProvider from "../components/common/toast/ToastProvider";
import { AxiosInterceptor } from "../axios/axiosInstance";
import AppRoutingConfig from "../assets/config/AppRoutingConfig";
import PageNotFound from "../components/pages/pageNotFound";
import AuthLayout from "../components/layouts/authLayout";
import SignInConfig from "../components/pages/auth/signIn/SignInConfig";
import ForgotPasswordConfig from "../components/pages/auth/forgotPassword/ForgotPasswordConfig";
import RequireAuth from "./requireAuth/RequireAuth";
import { appUserRouteUtils } from "../utils/routeNavigationUtils/appUserRouteUtils";
import { useAppSelector } from "../hooks/redux-hooks";
import ModuleLayout from "../components/layouts/moduleLayout";
import { ErrorBoundary } from "react-error-boundary";
import "./App.scss";

function App() {
  const { userPermission } = useAppSelector((state) => state.auth);

  const RoutesConfig = createHashRouter([
    {
      path: "*",
      element: <Navigate to={AppRoutingConfig.APP_URL_AUTH_PAGE_NOT_FOUND} />,
    },
    {
      path: AppRoutingConfig.APP_URL_AUTH_PAGE_NOT_FOUND,
      element: <PageNotFound />,
    },
    {
      element: (
        <AxiosInterceptor>
          <AuthLayout />
        </AxiosInterceptor>
      ),
      children: [...SignInConfig.routes, ...ForgotPasswordConfig.routes],
    },
    {
      element: (
        <AxiosInterceptor>
          <RequireAuth />
        </AxiosInterceptor>
      ),
      children: [
        {
          path: AppRoutingConfig.APP_URL_DEFAULT_HOME,
          element: <ModuleLayout />,
          children: appUserRouteUtils(userPermission),
        },
      ],
    },
  ]);

  const ErrorFallback = ({ error }: any) => (
    <div>
      <p>Something went wrong 😭</p>
      {error.message && <span>Here's the error: {error.message}</span>}
    </div>
  );

  return (
    <>
      <div className="App">
        <Suspense fallback={<Loader />}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
          >
            <ToastProvider>
              <RouterProvider router={RoutesConfig} />
            </ToastProvider>
          </ErrorBoundary>
        </Suspense>
      </div>
    </>
  );
}

export default App;
