import AdminConfig from "../../components/pages/modules/admin/AdminConfig";
import HrConfig from "../../components/pages/modules/hr/HrConfig";
import LeaveConfig from '../../components/pages/modules/leave/LeaveConfig'
import SalesConfig from "../../components/pages/modules/sales/SalesConfig";
import OperationsConfig from "../../components/pages/modules/operations/OperationsConfig";
import CrmConfig from "../../components/pages/modules/crm/CrmConfig";
import FleetConfig from "../../components/pages/modules/fleet/FleetConfig";
import FinanceConfig from '../../components/pages/modules/finance/FinanceConfig'

export const appUserRouteUtils = (userPermission:{
  adminDashboard?: boolean;
  cRM?: boolean;
  finance?: boolean;
  fleet?: boolean;
  hR?: boolean;
  leave?: boolean;
  operations?: boolean;
  sales?: boolean;
}) => {
  
  const tempRouteGroup = [];

  /* routeDashboard */
  userPermission?.adminDashboard && tempRouteGroup.push(...AdminConfig.routes);
  /* routeDashboard */

  /* routeHR */
  userPermission?.hR && tempRouteGroup.push(...HrConfig.routes);
  /* routeHR */

  /* routeLeave */
  userPermission?.leave && tempRouteGroup.push(...LeaveConfig.routes);
  /* routeLeave */

  /* routeOperations */
  userPermission?.operations && tempRouteGroup.push(...OperationsConfig.routes);
  /* routeOperations */

  /* routeSales */
  userPermission?.sales && tempRouteGroup.push(...SalesConfig.routes);
  /* routeSales */

  /* routeCrm */
  userPermission?.cRM && tempRouteGroup.push(...CrmConfig.routes);
  /* routeCrm */

  /* routeFleet */
  userPermission?.fleet && tempRouteGroup.push(...FleetConfig.routes);
  /* routeFleet */

  /* routeFinance */
  userPermission?.finance && tempRouteGroup.push(...FinanceConfig.routes);
  /* routeFinance */

  return tempRouteGroup;

};
