import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import AppRoutingConfig from "../../../assets/config/AppRoutingConfig";
import { useAdminMenuUtils } from '../../../utils/tabNavigationUtils/useAdminMenuUtils'
import { useHrMenuUtils } from '../../../utils/tabNavigationUtils/useHrMenuUtils'
import { useLeaveMenuUtils } from "../../../utils/tabNavigationUtils/useLeaveMenuUtils";
import { useOperationsMenuUtils } from '../../../utils/tabNavigationUtils/useOperationsMenuUtils'
import { useSalesMenuUtils } from "../../../utils/tabNavigationUtils/useSalesMenuUtils";
import { useCrmMenuUtils } from '../../../utils/tabNavigationUtils/useCrmMenuUtils';
import { useFleetMenuUtils } from '../../../utils/tabNavigationUtils/useFleetMenuUtils'
import { useFinanceMenuUtils } from "../../../utils/tabNavigationUtils/useFinanceMenuUtils";
import Sidebar from "../../common/sidebar";
import Navbar from "../../common/navbar";
import "./ModuleLayout.scss";
import Tabs from "../../common/tabs";

type Props = {};

const ModuleLayout = (props: Props) => {
  const location = useLocation();

  const adminMenuGroup = useAdminMenuUtils()
  const hrMenuGroup = useHrMenuUtils()
  const leaveMenuGroup = useLeaveMenuUtils()
  const operationsMenuGroup = useOperationsMenuUtils()
  const salesMenuGroup = useSalesMenuUtils()
  const crmMenuGroup = useCrmMenuUtils()
  const fleetMenuGroup = useFleetMenuUtils()
  const financeMenuGroup = useFinanceMenuUtils()

  const listRoutesArray = new Array(...adminMenuGroup, ...hrMenuGroup, ...leaveMenuGroup, ...operationsMenuGroup, ...salesMenuGroup, ...crmMenuGroup, ...fleetMenuGroup, ...financeMenuGroup)

  /* function definition to render the tabs based on module */
  const getTabsBasedOnModule = () => {
    const pathname = location.pathname;
    if (pathname.includes(AppRoutingConfig.APP_URL_ADMIN)) {
      return adminMenuGroup;
    } 
    else if (pathname.includes(AppRoutingConfig.APP_URL_HR)){
      return hrMenuGroup;
    }
    else if (pathname.includes(AppRoutingConfig.APP_URL_LEAVE)){
      return leaveMenuGroup;
    }  
    else if (pathname.includes(AppRoutingConfig.APP_URL_OPERATIONS)) {
      return operationsMenuGroup;
    } 
    else if (pathname.includes(AppRoutingConfig.APP_URL_SALES)){
      return salesMenuGroup
    }
    else if (pathname.includes(AppRoutingConfig.APP_URL_CRM)){
      return crmMenuGroup
    }
    else if (pathname.includes(AppRoutingConfig.APP_URL_FLEET)) {
      return fleetMenuGroup;
    }
    else if (pathname.includes(AppRoutingConfig.APP_URL_FINANCE)) {
      return financeMenuGroup
    }  
  };

  /* function definition to render the tabs based on module */


  return (
    <div className="main-layout-container">
      <Sidebar />
      <div className="content-wrapper">
        <Navbar />
          {
            listRoutesArray.some((element)=>element.url === location.pathname) ? 
              <Tabs tabsMenuUtils={getTabsBasedOnModule()} />
            :
            <Outlet/>
          }
      </div>
    </div>
  );
};

export default ModuleLayout;
