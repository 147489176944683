import AppRoutingConfig from "../../../../assets/config/AppRoutingConfig";
import ProjectSummaryConfig from "./projectSummary/ProjectSummaryConfig";
import OperationsDashboardConfig from "./dashboard/DashboardConfig";
import WorkFlowConfig from "./workFlow/WorkFlowConfig";
import CustomerManagementConfig from "./customerManagement/CustomerManagementConfig";
import { Navigate } from "react-router";

const OperationsConfig = {
  routes: [
    {
      path: AppRoutingConfig.APP_URL_OPERATIONS,
      element: (
        <Navigate to={AppRoutingConfig.APP_URL_OPERATIONS_DASHBOARD} replace />
      ),
    },
    ...OperationsDashboardConfig.routes,
    ...ProjectSummaryConfig.routes,
    ...WorkFlowConfig.routes,
    ...CustomerManagementConfig.routes,
  ]
};

export default OperationsConfig;
